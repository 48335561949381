import {SvgIcon} from "@mui/material";

function DiceD20NegatedIcon(props){
  return (
    <SvgIcon {...props}
     viewBox="0 0 512 512" version="1.1"
     xmlns="http://www.w3.org/2000/svg"
     xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/"
    >
      <path d="M336.377,124.799L271.6,8.2C268.8,3.1 263.4,-0 257.6,-0L254.4,-0C248.6,-0 243.2,3.2 240.4,8.2L160.4,152.2C154.5,162.9 162.2,176 174.4,176L285.176,176L336.377,124.799Z"/>
      <path d="M229.643,337.675L247.6,369.1C251.3,375.5 260.6,375.5 264.3,369.1L264.4,369.1L342.8,231.9C344.548,228.835 345.163,225.564 344.861,222.457L229.643,337.675Z"/>
      <path d="M403.786,57.39L322.1,1.4C320.7,0.5 319.1,0 317.5,0C311.3,0 307.4,6.7 310.4,12.1L359.945,101.23L403.786,57.39Z"/>
      <path d="M103.058,464.261L230.8,511.6C231.6,511.9 232.4,512 233.2,512C236.9,512 240,509 240,505.2L240,452.6C240,444.5 233.9,437.7 225.9,436.7L140.698,426.62L103.058,464.261Z"/>
      <path d="M409.11,158.209C409.478,158.022 409.842,157.819 410.2,157.6L463.4,125.7C472.498,120.241 473.65,107.913 466.555,100.763L409.11,158.209Z"/>
      <path d="M167.661,293.515L136.4,238.8C130.5,228.4 115.7,228 109.1,238L30.2,358.2C23.7,368.1 29.9,381.5 41.7,382.9L74.41,386.766L167.661,293.515Z"/>
      <path d="M253.176,208L183.1,208C170.8,208 163.1,221.3 169.2,231.9L191.046,270.13L253.176,208Z"/>
      <path d="M169.332,397.986L212.6,403.1C220.4,404 226,395.6 222.1,388.8L206.254,361.065L169.332,397.986Z"/>
      <path d="M48.7,125.8L101.9,157.7C109.7,162.4 119.7,159.7 124.1,151.8L201.6,12.1C204.6,6.7 200.7,0 194.5,0C192.9,0 191.3,0.5 189.9,1.4L47.9,98.8C38.3,105.4 38.7,119.7 48.7,125.7L48.7,125.8Z"/>
      <path d="M16,171.7L16,295.3C16,303.3 26.4,306.3 30.7,299.7L90.7,207.7C95.7,200.1 93.3,189.9 85.5,185.2L40.2,158C29.6,151.6 16,159.3 16,171.7Z"/>
      <path d="M496,171.7C496,159.3 482.4,151.6 471.8,158L426.5,185.2C418.7,189.9 416.4,200.1 421.3,207.7L481.3,299.7C485.6,306.4 496,303.3 496,295.3L496,171.7Z"/>
      <path d="M446.7,417.7L286.1,436.6C278,437.5 272,444.4 272,452.5L272,505.3C272,509 275,512.1 278.8,512.1C279.6,512.1 280.4,512 281.2,511.7L453.9,447.7C460,445.5 464,439.7 464,433.2C464,423.9 455.9,416.7 446.7,417.8L446.7,417.7Z"/>
      <path d="M481.3,358.1L402.9,238.1C396.4,228.1 381.5,228.5 375.6,238.9L290.2,388.5C286.3,395.3 291.8,403.7 299.7,402.8L469.8,382.8C481.6,381.4 487.8,368.1 481.3,358.2L481.3,358.1Z"/>
      <g transform="matrix(0.906183,-0.906183,1.57301,1.57301,-164.767,293.032)">
        <path d="M498.908,122.419C498.908,116.212 490.161,111.173 479.386,111.173L27.351,111.173C16.576,111.173 7.829,116.212 7.829,122.419C7.829,128.626 16.576,133.665 27.351,133.665L479.386,133.665C490.161,133.665 498.908,128.626 498.908,122.419Z"/>
      </g>
</SvgIcon>
  )
}

export default DiceD20NegatedIcon;
